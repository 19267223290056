import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { resetSheetToCloudAlignment } from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { SheetToCloudAlignmentModeOverlay } from "./sheet-to-cloud-alignment-mode-overlay";
import { SheetToCloudAlignmentModeScene } from "./sheet-to-cloud-alignment-mode-scene";

export const sheetToCloudAlignmentMode: Mode = {
  name: "sheetToCloudAlignment",
  ModeScene: SheetToCloudAlignmentModeScene,
  ModeOverlay: SheetToCloudAlignmentModeOverlay,
  exclusive: {
    title: "Align sheet to Point Cloud",
    onBack({ dispatch }) {
      // if user canceled alignment by pressing back we need to reset
      // temporary data to prevent reusing it in the next session of alignment
      dispatch(resetSheetToCloudAlignment());

      dispatch(changeMode("start"));

      return Promise.resolve();
    },
  },
};
